import Vue from 'vue';
import {
  getBlocks,
  postBlocks,
  getBlocksId,
  putBlocksId,
  deleteBlocksId,
  postBlocksIdCommercial,
  deleteBlocksIdCommercial,
  getBlockSpots,
  postDownSpot,
  postUpSpot,
  postFixSpot,
  postUnfixSpot,
  postBlocksDoubleSpot,
  deleteBlocksIdSpots,
} from '../../api/apiServices';

export default {
  state: {
    modalEditBlocks: '',
    blocks: '',
    blocksStatus: '',
    spots: '',
    copiedSpot: '',
  },
  mutations: {
    getModalEditBlocks(state, payload) {
      Vue.set(state, 'modalEditBlocks', payload.data);
    },
    getBlocksRequest: (state) => {
      state.blocksStatus = 'loading';
    },
    getBlocksSuccess: (state, res) => {
      state.blocksStatus = 'success';
      Vue.set(state, 'blocks', res);
    },
    getBlocksError: (state) => {
      state.blocksStatus = 'error';
    },
    getBlockSpotsSuccess: (state, res) => {
      Vue.set(state, 'spots', res);
    },
    setCopiedSpot: (state, res) => {
      Vue.set(state, 'copiedSpot', res);
    },
    clearCopiedSpot: (state) => {
      Vue.set(state, 'copiedSpot', '');
    },
    clearBlockSpots: (state, res) => {
      Vue.set(state, 'spots', '');
    },
  },
  actions: {
    async GET_BLOCKS({ dispatch, commit }, payload) {
      commit('getBlocksRequest');
      await getBlocks(payload)
        .then((res) => {
          commit('getBlocksSuccess', res.data);
        })
        .catch((e) => {
          commit('getBlocksError');
        });
    },
    async POST_BLOCKS({ dispatch, commit }, payload) {
      await postBlocks(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async GET_BLOCKS_ID({ dispatch, commit }, payload) {
      await getBlocksId(payload)
        .then((res) => {
          commit('getModalEditBlocks', res.data);
        })
        .catch((e) => {
          payload.handlerError(e);
        });
    },
    async PUT_BLOCKS({ dispatch, commit }, payload) {
      const id = payload.blockId;
      await putBlocksId(id, payload.formData)
        .then((res) => {
          payload.handler(res?.data);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async DELETE_BLOCKS_ID({ dispatch, commit }, payload) {
      await deleteBlocksId(payload.formData)
        .then((res) => {
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    // Spots in Block for Booking
    async POST_COMMERCIAL_IN_BLOCKS({ dispatch, commit }, payload) {
      const id = payload.blockId;
      await postBlocksIdCommercial(id, payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    async DELETE_SPOTS_IN_BLOCK({ dispatch, commit }, payload) {
      await deleteBlocksIdSpots(payload.blockId)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    async POST_DOUBLE_SPOT_IN_BLOCKS({ dispatch, commit }, payload) {
      await postBlocksDoubleSpot(payload.blockId, payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    async DELETE_COMMERCIAL_IN_BLOCKS({ dispatch, commit }, payload) {
      await deleteBlocksIdCommercial(payload.blockId, payload.commercialId)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    //Get spots for block
    async GET_BLOCK_SPOTS({ dispatch, commit }, payload) {
      await getBlockSpots(payload)
        .then((res) => {
          commit('getBlockSpotsSuccess', res.data.data);
        })
        .catch((e) => {
          payload.handlerError(e);
        });
    },

    //Spot up in block
    async POST_SPOT_UP({ dispatch, commit }, payload) {
      await postUpSpot(payload.blockId, payload.formData, payload.data)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    //Spot down in block
    async POST_SPOT_DOWN({ dispatch, commit }, payload) {
      await postDownSpot(payload.blockId, payload.formData, payload.data)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },

    //Spot fix/unfix
    async POST_SPOT_FIX({ dispatch, commit }, payload) {
      await postFixSpot(payload.blockId, payload.spotId, payload.formData)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async POST_SPOT_UNFIX({ dispatch, commit }, payload) {
      await postUnfixSpot(payload.blockId, payload.spotId)
        .then((res) => {
          payload.handler(res);
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
  },
  getters: {
    getModalEditBlocks: (state) => state.modalEditBlocks,
    getBlocks: (state) => state.blocks,
    getBlocksStatus: (state) => state.blocksStatus,
    getBlockSpotsList: (state) => state.spots || {},
    getCopiedSpot: (state) => state.copiedSpot,
  },
};
