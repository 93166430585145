import Vue from 'vue';
import { postLogin, postMe, postLogout, postRefresh } from '../../api/apiServices';

export default {
  state: {
    user: '',
    currentUserPermissions: '',
    currentUserRole: '',
    loginStatus: '',
  },
  mutations: {
    postLoginRequest: (state) => {
      state.loginStatus = 'loading';
    },
    postLoginSuccess: (state, res) => {
      state.loginStatus = 'success';
    },
    postLoginError: (state) => {
      state.loginStatus = 'error';
    },
    userMeSuccess: (state, res) => {
      Vue.set(state, 'user', res);
    },
    setCurrentUserPermissions(state, res) {
      Vue.set(state, 'currentUserPermissions', res);
    },
    setCurrentUserRole(state, res) {
      Vue.set(state, 'currentUserRole', res);
    },
    clearUser: (state) => {
      state.user = '';
      state.currentUserRole = '';
      state.currentUserPermissions = '';
      state.loginStatus = '';
    },
  },
  actions: {
    async POST_LOGIN({ dispatch, commit }, payload) {
      commit('postLoginRequest');
      await postLogin(payload.formData)
        .then((res) => {
          const token = res.data.access_token;
          const maxAge = res.data.expires_in;
          const cookieExpire = Date.now() + maxAge * 1000;
          Vue.$cookies.set('token', token, maxAge, '/');
          Vue.$cookies.set('tokenCookieExpireIn', cookieExpire, 0, '/');
          payload.handler();
          commit('postLoginSuccess');
        })
        .catch((err) => {
          payload.handlerError(err);
          commit('postLoginError');
        });
    },
    async POST_LOGOUT({ dispatch, commit }, payload) {
      await postLogout()
        .then((res) => {
          Vue.$cookies.remove('token');
          Vue.$cookies.remove('tokenCookieExpireIn');
          localStorage.removeItem('currentUserPermissions');
          localStorage.removeItem('currentUserRole');
          commit('clearUser');
          commit('clearChannelsList');
          commit('clearBrandsList');
          commit('clearSalesHousesList');
          commit('clearAdvertisersList');
          commit('clearAgenciesList');
          commit('clearProjects');
          payload.handler();
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async POST_ME({ dispatch, commit }, payload) {
      await postMe()
        .then((res) => {
          commit('userMeSuccess', res.data.data);
          const userPerms = res.data.data.permissions.map((item) => item.name);
          commit('setCurrentUserPermissions', userPerms);
          const userRole = res.data.data.roles && res.data.data.roles.length > 0 ? res.data.data.roles[0].name : '';
          commit('setCurrentUserRole', userRole);
          localStorage.setItem('currentUserPermissions', userPerms);
          localStorage.setItem('currentUserRole', userRole);
          if (payload.handler) payload.handler(); // optional handler
        })
        .catch((err) => {
          payload.handlerError(err);
        });
    },
    async REFRESH_TOKEN({ dispatch, commit }, payload) {
      await postRefresh()
        .then((res) => {
          const token = res.data.access_token;
          const maxAge = res.data.expires_in;
          const cookieExpire = Date.now() + maxAge * 1000;
          Vue.$cookies.remove('token');
          Vue.$cookies.remove('tokenCookieExpire');
          Vue.$cookies.set('token', token, maxAge, '/');
          Vue.$cookies.set('tokenCookieExpireIn', cookieExpire, 0, '/');
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  getters: {
    getLoginStatus: (state) => state.loginStatus,
    getUser: (state) => state.user,
    getCurrentUserPermissions: (state) => state.currentUserPermissions || [],
    getCurrentUserRole: (state) => state.currentUserRole || '',
    getIsCurrentUserChannelManager: (state) => state.currentUserRole === 'Account manager channel',
    getIsCurrentUserAdmin: (state) => state.currentUserRole === 'Administrator',
    getIsCurrentUserAgencyManager: (state) => state.currentUserRole === 'Account manager agency',
    getIsCurrentUserChief: (state) => state.currentUserRole === 'Chief',
    getIsCurrentUserChiefChannel: (state) => state.currentUserRole === 'Chief channel',
    getIsCurrentUserChiefSaleshouse: (state) => state.currentUserRole === 'Chief saleshouse',
    getIsCurrentUserMarketingManager: (state) => state.currentUserRole === 'Marketing manager',
    getIsCurrentUserSaleshouseManager: (state) => state.currentUserRole === 'Account manager saleshouse',
  },
};
